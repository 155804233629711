@tailwind base;
@tailwind components;
@tailwind utilities;

.page-card {
  @apply max-w-2xl mx-auto bg-gray-600 text-gray-300 sm:rounded-xl shadow-lg h-full;
}

html,
body,
#root {
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
